export enum Roles{
    CompanyOwner ="company owner"
}

export enum ContentTypes {
	ARTICLE = "article",
	AUDIO = "audio",
	VIDEO = "video",
	QUICK_INFO = "quickinfo",
	EXTERN = "extern",
	EVENT = "event",
}

export enum ActionTypes {
	LOCAL = "local",
	EXTERN = "extern",
}

export enum ScriptTypes {
	SRC = "src",
	CONTENT = "content",
}
